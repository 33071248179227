.checkBoxes {
  margin-top: 1rem;
}

.subText {
  margin-top: 1.5rem;
  font-size: 0.8rem;
  color: #aaaaaa;
  line-height: 20px;
  text-align: left;
  font-weight: 400;
  font-style: normal;
}

.noBullets {
  list-style-type: none;
}
