.icons {
  margin-top: 0.5rem;
  padding-bottom: -0.5rem;
}

.labels {
  font-size: 14px;
}

.items-container {
  display: flex;
  align-items: center;
}

.footerlink-icon-button {
  margin-top: 1.7rem !important;
}

.footerlinksButtonContainer {
  display: flex;
  justify-content: space-between;
}

.formContainer .fjd-stack--orientation-horizontal {
  align-items: unset;
}
