.listTest {
  max-height: 13.5rem;
}
.orgUnitNameTextInput {
  margin-bottom: 1.5rem;
}
.dataModel_upload_section_info {
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  color: #333333;
  text-align: left;
  line-height: normal;
  font-size: 0.87rem;
  margin-bottom: 1rem;
}

.sectionTitle {
  font-weight: bold;
  font-style: normal;
  font-size: 0.87rem;
  font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', sans-serif;
  margin-left: 1.5rem;
  margin-bottom: -1rem;
}

.datamodel_card {
  padding: 1.25rem 2.4rem 0px 0px;
  border-radius: 1px;
  border: 0px solid #d7d7d7;
  height: 18rem;
  width: 36.1rem !important;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
}

.errorLabel {
  font-size: 0.87rem;
  color: red;
  margin-left: 1.5rem;
}

.datamodel-orgunit-item {
  margin-left: 1.25rem;
}

.listOfPublicServiceTypes {
  margin-top: 2rem;
}

.tooltip-button-adjust-top {
  margin-top: -1.25rem;
}

.listOfPublicServiceTypes {
  margin-top: 2rem;
}
