@-webkit-keyframes fadein {
  from {
    bottom: -1rem;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: -1rem;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.sticky {
  position: fixed;
  bottom: 0;
  z-index: -1;
  width: 100vw;
  height: 3%;
}

.stickyVisible {
  background-color: white;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  z-index: 50;
}
