.stackLeft {
  width: auto;
  min-width: 15rem;
}

.emailModal {
  padding-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
