.orgUnitNameTextInput {
  margin-bottom: 1.5rem;
}
.section {
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  color: #333333;
  text-align: left;
  line-height: normal;
  font-size: 0.87rem;
  margin-bottom: 1rem;
}

.sectionTitle {
  font-weight: bold;
  font-style: normal;
  font-size: 0.87rem;
  font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', sans-serif;
  margin-left: 1.5rem;
  margin-bottom: -1rem;
}

.label {
  font-size: 9px;
}

.card {
  padding: 20px 20px 20px 20px;
  border-radius: 1px;
  border: 1px solid #d7d7d7;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
}

.errorLabel {
  font-size: 0.87rem;
  color: red;
  margin-left: 1.5rem;
}

.orgunit-modal {
  width: 43rem;
  min-width: 43rem;
}
