.nachrichtContainer:hover {
  background-color: #f5f6f7;
}

.nachrichtContainer {
  margin-bottom: -60px;
  margin-top: -35px;
  margin-left: -20px;
}

.nachrichtCreatedDate {
  margin-left: 35px;
}

.bescheid {
  width: auto;
}

.vonBescheidPositive {
  background-color: rgba(236, 201, 128, 0.48);
  border-radius: 4px;
  padding: 4px;
}

.vonBescheidNegative {
  background-color: rgba(128, 195, 236, 0.48);
  border-radius: 4px;
  padding: 4px;
}

.betreff {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-weight: 700;
  color: #333333;
  text-align: left;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selectedNachricht {
  margin-bottom: 40px;
  background-color: #f5f6f7;
  border-radius: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.notSelectedNachricht {
  margin-bottom: 40px;
  border-radius: 10px;
  padding-left: 10px;
  cursor: pointer;
}

#selectedNachricht {
  background-color: #f5f6f7;
}

#notSelectedNachricht {
  background-color: #f5f6f7;
}

.unread {
  border-left: 6px solid #1e73be;
}

.unread > .notSelectedNachricht {
  margin-left: -6px;
}

.noHover {
  pointer-events: none;
}
