.headerLogos {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;

  &.behLogo {
    margin-left: 1rem;
  }

  &.clientLogo {
    margin-left: 2rem;
  }
}
