.usersAndRolesManagement-row-menu > div > div {
  width: fit-content !important;
}

.usersAndRolesManagement-row-menu
  > div
  > div
  > ul
  > li.usersAndRolesManagement-row-menu-item {
  --fjd2-stack-flexwrap: unset !important;
  width: 20rem;
}

.highlighted-row,
.highlighted-row > td {
  background-color: #ecf1f8;
}

.dragging-row,
.dragging-row > td {
  box-shadow: none !important;
}

.disabled.disabledWorkaroundMoreSpecificHelper
  *:has(> .fjd2-input_label--hidden)[style] {
  &::before {
    background-color: var(--fjd2-neutral-grey-500) !important;
    cursor: default !important;
  }
}

.disabled.disabledWorkaroundMoreSpecificHelper
  input:checked:hover:not([aria-disabled='true']):not([readonly])
  + span[style] {
  &::before {
    background-color: var(--fjd2-neutral-grey-500) !important;
    cursor: default !important;
  }
}

.no-list-style {
  list-style-type: none;
}
