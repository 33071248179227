.datamodel-modal {
  width: 65rem;
  min-width: 65rem;
}

.datamodel-file-list-item {
  max-width: 30rem;
}

.modalSuccessContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
