.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.file_uploaded_by_authority {
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  background-color: rgba(128, 195, 236, 0.48);
  box-sizing: border-box;
  color: #666666;
  text-align: left;
  line-height: normal;
}

.file_not_uploaded_by_authority {
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  background-color: rgba(236, 201, 128, 0.48);
  box-sizing: border-box;
  color: #666666;
  text-align: left;
  line-height: normal;
}
