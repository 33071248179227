.leftbox {
  float: left;
  width: 60%;
  border: 2px solid #d7d7d7;
  height: 100%;
  padding: 32px;
  background-color: rgba(255, 255, 255, 0);
}

.rightbox {
  float: right;
  width: 40%;
  border: 2px solid #d7d7d7;
  border-top: none;
  padding: 32px;
  background-color: rgba(255, 255, 255, 0);
}
h1 {
  margin-bottom: 10px;
}

p {
  font-size: 12px;
}

dt {
  width: 150px;
  height: 16px;
  font-weight: 700;
  color: #333333;
  text-align: left;
  line-height: normal;
  float: left;
  clear: left;
  font-weight: bold;
  margin-bottom: 16px;
  padding-top: 3px;
}
dd {
  float: left;
}

.vermerk {
  clear: both;
  padding-top: 16px;
}

.vermerkText {
  width: auto;
  font-family: 'Open Sans';
  font-style: italic;
  color: #333333;
  text-align: left;
  line-height: normal;
  size: 0.813rem;
}
.border {
  margin-bottom: -1.875rem;
  border-radius: 0.188rem;
  border-bottom: 0.063rem solid #d7d7d7;
}
.vermerkTime {
  color: #757678;
  font-family: 'Open Sans';
  margin-top: -15px;
}

@media (max-width: 1342px) {
  .vermerkText {
    width: 100%;
  }
}

.data {
  font-style: italic;
}

.collapsibleCardBody {
  margin-top: 16px;
}

.zuweisung {
  float: right;
  width: auto;
  height: 16px;
  line-height: normal;
  padding-top: 32px;
}

.wrapper {
  margin-top: 20px;
}

.caseNameTitle {
  margin-bottom: 32px;
}

.container {
  position: relative;
}

.zahlung {
  color: black;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 16px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  margin-top: -20px;
}

.scroll {
  overflow-y: hidden;
  padding-bottom: 3px;
}

.antragsheader {
  border-bottom: 2px solid #e8e8e8;
  margin-top: 60px;
}

.formularansicht {
  float: right;
  margin-top: -66px;
  display: flex;
  flex-direction: row;
}

.noFormularAnsicht {
  margin-top: 40px;
}

.bearbeiter {
  display: block !important;
  margin-bottom: 10px;
  text-align: right;
}
.filterHeading {
  padding-top: 1.75rem;
}
