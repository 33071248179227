@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url('https://static.efast.digital/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: normal;
  src: url('https://static.efast.digital/fonts/Open_Sans/static/OpenSans/OpenSans-Italic.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  src: url('https://static.efast.digital/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: bold;
  src: url('https://static.efast.digital/fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf')
    format('truetype');
}

body,
html {
  font-family: 'Open Sans';
}

.fjd-alert {
  width: 100%;
  max-width: 76rem;
}
