.container {
  display: flex;
  flex-direction: row;
  margin-top: -20px;
}

.left {
  float: left;
  width: 35%;
  border: 2px solid #d7d7d7;
  padding: 32px;
  border-top: none;
}

.right {
  float: right;
  width: 65%;
  flex: 1;
  border: 2px solid #d7d7d7;
  padding: 32px;
}

.wrapper {
  background-color: #6fa5d5;
}

.nachrichtenContainer {
  margin-bottom: 40px;
}
