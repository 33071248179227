.containerKeys {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2.5rem;
  margin-bottom: 1rem;
}

.desc {
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.87rem;
}
