::placeholder {
  opacity: 1;
}

.fjd-status__dot {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.fjd-virtualized-table__table {
  overflow: visible !important;
}

.fjd-virtualized-table {
  overflow: visible !important;
  margin-top: 1.5rem;
}

.fjd-alert {
  margin-top: 1rem;
  width: 76rem;
}
