.containerFooterlinksSettings {
  display: block;
  width: 70rem;
}

.containerFooterlinksSettings .bezInputRow {
  min-width: 30rem;
}

.containerFooterlinksSettings .urlInputRow {
  min-width: 30rem;
}

.containerFooterlinksSettings .fjd-card {
  border: none;
}

.containerFooterlinksSettings .fjd-card__content {
  padding: 0 !important;
}

.containerFooterlinksSettings .footerlinksButtonContainer {
  margin-right: 3.95rem;
}

.containerFooterlinksSettings > * {
  font-size: 1rem;
  font-family:
    'Open Sans',
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    'Helvetica Neue',
    sans-serif;
}
