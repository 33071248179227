.uploadField {
  width: 100%;
  margin-bottom: 1rem;
}

.orgUnitName {
  width: 11rem;
  display: block;
}

.orgUnitName > div {
  font-weight: 700;
}
