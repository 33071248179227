.caseTitle {
  overflow-wrap: break-word;
  white-space: break-spaces;
  webkit-line-clamp: 2;
  line-clamp: 2;
}

.fontWeightBold {
  font-weight: bold;
}
