progress::-webkit-progress-value {
  transition: width 1s ease;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.successState {
  border-color: var(--fjd2-semantic-primary-600);
}

.fjd2-card {
  width: 40rem !important;
}

.fjd2-card-content {
  padding: 0 !important;
}

.cardContent {
  padding: 0;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.fjd2-progress-bar {
  margin-bottom: 1rem !important;
}

.heading {
  font-weight: bold;
  font-style: normal;
  font-size: 1.12rem;
  text-align: left;
  font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', sans-serif;
  margin-bottom: 1.3rem;
  margin-top: 1.2rem;
}

.contentHeading {
  font-weight: bold;
  font-style: normal;
  font-size: 0.87rem;
  text-align: left;
  font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', sans-serif;
  margin-bottom: 1.3rem;
}

.descriptionText {
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.87rem;
}

.fjd2-checkbox {
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 0.87rem !important;
}

.fjd2-section-footer {
  border-top: 0 !important;
  --fjd2-stack-direction: unset !important;
}

.cardFooter {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  margin-top: 2rem !important;
  width: 90% !important;
  margin-bottom: 2rem !important;
}

.cardFooter > button {
  margin-left: 1rem !important;
}

.cardFooter > .fjd2-button--appearance-tertiary {
  color: var(--fjd2-color-action-tertiary-color) !important;
}
