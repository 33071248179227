:root {
  --fontSize: 1rem;
}

.headerStyle {
  font-size: var(--fontSize);
  font-style: normal;
  font-weight: 700;
}

.valueStyle {
  font-size: var(--fontSize);
  font-style: normal;
  font-weight: 400;
}

.keineAngabenStyle {
  font-size: var(--fontSize);
  font-style: italic;
  font-weight: 400;
}
